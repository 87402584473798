var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-handshake")]),_vm._v(" Benefícios "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","placeholder":"Informe a Descrição do beneficio","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscar()},"click:append":function($event){return _vm.buscar()}},model:{value:(_vm.benefitPagination.search),callback:function ($$v) {_vm.$set(_vm.benefitPagination, "search", $$v)},expression:"benefitPagination.search"}}),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.benefitStatusList,"label":"Status","hide-details":"","item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.buscar()}},model:{value:(_vm.benefitPagination.status),callback:function ($$v) {_vm.$set(_vm.benefitPagination, "status", $$v)},expression:"benefitPagination.status"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"#3D1159","dark":"","to":{ name: 'benefitAdd' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1),(_vm.benefits)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.benefits.items,"options":_vm.options,"server-items-length":_vm.benefits.totalItems,"item-key":"id","footer-props":{
                      'items-per-page-options': [10, 25, 50, 75, 100]
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.value))+" ")]}},{key:"item.date",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate2")(item.date))+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#3D1159","icon":"","to":{
                  name: 'benefit',
                  params: { id: item.id },
                }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}],null,false,2611150011),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(!_vm.benefits.items)?_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-alert',{attrs:{"value":true,"type":"warning","outlined":""}},[_vm._v(" Nenhum benefício encontrado ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }