<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12" xl="12">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-handshake</v-icon>
          Benefícios
          <v-spacer></v-spacer>
          <v-text-field
            v-model="benefitPagination.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe a Descrição do beneficio"
            single-line
            hide-details
            @keydown.enter="buscar()"
            @click:append="buscar()"
          />
          <v-spacer></v-spacer>
          <v-select
            v-model="benefitPagination.status"
            :items="benefitStatusList"
            label="Status"
            hide-details
            item-text="name"
            item-value="id"
            @change="buscar()"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn color="#3D1159" dark :to="{ name: 'benefitAdd' }">
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="benefits.items"
          :options.sync="options"
          :server-items-length="benefits.totalItems"
          item-key="id"
          class="elevation-1"
          v-if="benefits"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.value="{ item }">
            {{ item.value | money }}
          </template>

          <template v-slot:item.date="{ item }">
            {{ item.date | formatDate2 }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'benefit',
                    params: { id: item.id },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-row v-if="!benefits.items">
          <v-col sm="12">
            <v-alert :value="true" type="warning" outlined>
              Nenhum benefício encontrado
            </v-alert>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {BENEFIT_SEARCH} from "@/store/actions/benefit.type";
import {BENEFIT_RESET_STATE, SET_BENEFIT_PAGINATION} from "@/store/mutations/benefit.type";

export default {
  name: "Benefits",

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(BENEFIT_RESET_STATE),
      store.dispatch(BENEFIT_SEARCH),
    ]).then(() => {
      next();
    });
  },

  data: () => ({
    selected: [],
    options: {},
    headers: [
      { text: "Status", value: "benefitStatus", width: 150 },
      { text: "Descrição", value: "desc", width: 150 },
      { text: "Valor", value: "value", width: 150 },
      { text: "Data", value: "date", width: 150 },
      {
        text: "Visualizar",
        value: "actions",
        sortable: false,
        align: "center",
        width: 150,
      },
    ],

    benefitStatusList: [
      {id: 1, name: "Aberto"},
      {id: 2, name: "Aguardando Pagamento"},
      {id: 3, name: "Pago"},
      {id: 4, name: "Cancelado"},
      {id: 5, name: "Expirado"},
      {id: 6, name: "Pagamento Confirmado"},
      {id: 7, name: "Aguardando Cobrança"}]
  }),
  methods:{
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options

      store.commit(SET_BENEFIT_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(BENEFIT_SEARCH);
    }
  },
  watch: {
    options: {
      handler() {
        this.buscar()
      }
    }
  },
  computed: {
    ...mapGetters(["benefits", "benefitPagination", "checkUsers"]),
  },
};
</script>
